import React from 'react';
import { useProductImages } from '../hooks';
import MasonryLayout from './MasonryLayout';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface ProductListProps {
  products: IGatsbyImageData[];
}

const ProductList = ({ products }: ProductListProps) => {
  const grid = products.map((product) => (
    <GatsbyImage image={product} alt="Stained glass" />
  ));

  return (
    <div>
      <MasonryLayout columns={3} gap={4}>
        {grid}
      </MasonryLayout>
    </div>
  );
};

export default ProductList;
