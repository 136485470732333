import * as React from 'react';
import { shuffle, take } from 'lodash';

import Layout from '../components/layout';
import ProductList from '../components/ProductList';
import Seo from '../components/seo';
import { useCustomImages, useProductImages } from '../hooks';

const IndexPage = () => {
  const products = shuffle(useProductImages());
  const custom = shuffle(useCustomImages());
  const allImages = products.concat(custom);

  // only want a multiple of 3 since we have 3 columns
  const totalToTake = Math.floor(allImages.length / 3) * 3;
  const subsetOfProducts = take(allImages, totalToTake);

  return (
    <Layout pageTitle="Glowing Glass Designs">
      <section className="section">
        <div className="content has-text-centered">
          <h3 className="subtitle is-2 welcome style">
            Stained glass art made to order
          </h3>
          <p>
            Whether you are looking for a family heirloom or a cute cat
            ornament, thanks for stopping by!
          </p>
          <p></p>
        </div>
        <div>
          <ProductList products={subsetOfProducts} />
        </div>
      </section>
    </Layout>
  );
};

export const Head = () => <Seo />;

export default IndexPage;
