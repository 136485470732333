import React from 'react';

interface MasonryLayoutProps {
  columns: number | undefined;
  gap: number | undefined;
  children: any[];
}

const MasonryLayout = (props: MasonryLayoutProps) => {
  const { columns = 2, gap = 20, children } = props;

  const columnWrapper: Record<string, any> = {};
  const result = [];

  // create columns
  for (let i = 0; i < columns; i++) {
    const columnKey = `column${i}`;
    columnWrapper[columnKey] = [];
  }

  // divide children into columns
  for (let i = 0; i < children.length; i++) {
    const columnIndex = i % columns;
    const columnKey = `column${columnIndex}`;
    columnWrapper[columnKey].push(
      <div style={{ marginBottom: `${gap}px` }}>{children[i]}</div>
    );
  }

  // wrap children in each column with a div
  for (let i = 0; i < columns; i++) {
    result.push(
      <div
        style={{
          marginLeft: `${i > 0 ? gap : 0}px`,
          flex: '1'
        }}
      >
        {columnWrapper[`column${i}`]}
      </div>
    );
  }

  return <div style={{ display: 'flex' }}>{result}</div>;
};

export default MasonryLayout;
